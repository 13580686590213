import React from 'react';
import { PageProps } from 'gatsby';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'gatsby-plugin-react-intl';

import { Article } from '../../theme/typography';
import { Navigation } from '../../shared/components/navigation';
import { Footer } from '../../shared/components/footer';
import { ContactFormSection } from '../../shared/components/contactFormSection';
import { ProductGallery } from '../../shared/components/productGallery';

interface IndexProps {
  site: {
    buildTime: string;
  };
}

const Wytaczarki: React.FC<PageProps<IndexProps>> = () => {
  const { formatMessage } = useIntl();

  return (
    <Article>
      <Navigation />
      <Helmet
        htmlAttributes={{
          lang: 'pl',
        }}
        title={formatMessage({ id: 'products.product_1.title' })}
        meta={[
          {
            name: 'description',
            content: formatMessage({ id: 'products.product_1.image_alt' }),
          },
        ]}
      />
      <section className="bg-white body-font w-full">
        <div className="container px-5 pt-32 mx-auto flex flex-col lg:flex-row">
          <div className="flex flex-col w-full lg:w-1/2 lg:pr-5">
            <ProductGallery
              images={[
                '/przyrzady-pomiarowe/srednicowka-przyrzad-do-pomiaru-srednic-otworow-wewnetrznych-wytaczarek-4.webp',
                '/przyrzady-pomiarowe/srednicowka-przyrzad-do-pomiaru-srednic-otworow-wewnetrznych-wytaczarek-2.webp',
                '/przyrzady-pomiarowe/srednicowka-przyrzad-do-pomiaru-srednic-otworow-wewnetrznych-wytaczarek-3.webp',
                '/przyrzady-pomiarowe/srednicowka-przyrzad-do-pomiaru-srednic-otworow-wewnetrznych-wytaczarek-1.webp',
              ]}
            />
          </div>
          <div className="flex flex-col w-full lg:w-1/2 lg:pl-5">
            <h1 className="text-3xl font-extrabold text-left md:text-4xl">
              {formatMessage({ id: 'products.product_1.title' })}
            </h1>
            <div className="flex flex-col sm:flex-row">
              <div className="w-full sm:py-8 mt-4 pt-4 sm:mt-0 text-center sm:text-left">
                <p className="mb-8 leading-relaxed text-gray-500">
                  {formatMessage({ id: 'products.product_1.description' })}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ContactFormSection />
      <Footer />
    </Article>
  );
};

export default Wytaczarki;
